

@media(max-width: 991px) {
    .LoginLogoWrapper {
        width: '50%'
    }
} 

 @media(min-width: 992px) {
    .LoginLogoWrapper {
       width: '25%'
    }
}
