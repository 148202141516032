@import url("./theme/QRCode.css");
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f9f9f9 !important;
}

* {
  font-family: "Source Sans Pro";
}

option {
  font-family: "Source Sans Pro";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  min-height: 682px;
}

@supports (-moz-appearance:none) {
  select
  {
  -moz-appearance:none !important;
  }
}